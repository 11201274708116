<template>
	<div class="cells">
		<div class="title">
			细胞质量检测技术服务平台
		</div>
		<div class="title2">
			<img src="https://khome2.tuzuu.com/klfront/cells.png" />
			<span>细胞质量查询</span>
		</div>
		<div class="radio">
			<div class="item" :class="{'active':active == 1}" @click="changeIndex(1)">存储细胞</div>
			<div class="item" :class="{'active':active == 2}" @click="changeIndex(2)">临床研究细胞</div>
		</div>
		<van-form @submit="onSubmit" class="form">
			<van-field v-model="username" name="合同编码" label="合同编码" :rules="[{ required: true, message: '请填写合同编码' }]" />
			<van-field v-model="din" name="DIN码" label="DIN码" :rules="[{ required: true, message: '请填写DIN码' }]" />
			<div style="margin: 16px;">
				<van-button round block type="info" color="#36adb3" native-type="submit">点击查询</van-button>
			</div>
		</van-form>
		<div class="desc">
			温馨提示：目前可查询到2019年1月1日起取样制备的细胞各项检测数据
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				active: 1,
				username: '',
				din: ''
			}
		},
		methods: {
			changeIndex(index) {
				this.active = index
			},
			onSubmit() {
				this.$toast('暂无报告')
			}
		},

	}
</script>

<style scoped lang="less">
	.cells {
		.title {
			font-size: 16px;
			text-align: center;
			margin-top: 20px;
			font-weight: 700;
			color: #36adb3;
		}

		.title2 {
			margin-top: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				width: 30px;
				margin-right: 10px;
			}
			span {
				font-size: 30px;
				color: #36adb3;
				font-weight: 700;
				letter-spacing: 3px;
			}
		}
		.radio {
			width: 70%;
			display: flex;
			margin: auto;
			box-sizing: border-box;
			margin-top: 50px;
			border-radius: 5px;
			border: 2px solid #36adb3;

			.item {
				flex: 1;
				font-weight: 700;
				font-size: 14px;
				text-align: center;
				padding: 8px 0px;
				color: #e5eaea;

				&:first-of-type {
					border-right: 1px solid #36adb3;
					;
				}
			}

			.active {
				background-color: #36adb3;
				color: white;
			}
		}

		.form {
			width: 75%;
			margin: 0 auto;
			margin-top: 40px;
		}

		/deep/ .van-field__label {
			white-space: nowrap;
		}
		.desc{
			font-size: 12px;
			color: #999999;
			width: 70%;
			margin-top: 20px;
			margin: 0 auto;
			line-height: 25px;
		}
	}
</style>
